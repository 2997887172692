import { createSlice } from "@reduxjs/toolkit";

const smartPaperFlowSlice = createSlice({
  name: "smartPaperFlow",
  initialState: {
    uploadState: 1,
    teJobId: 0,
    resultsForDownload: 0,
    uploadedFileName: "",
    resultsReceived: false,
    smartPaperFlowData: null,
    isCancelled: false,
  },
  reducers: {
    setUploadStateSmartPaperFlow: (state, action) => {
      state.uploadState = action.payload;
    },
    setJobIdSmartPaperFlow: (state, action) => {
      state.teJobId = action.payload;
    },
    setCancelled: (state, action) => {
      state.isCancelled = action.payload;
    },
    setResultsForDownload: (state, action) => {
      state.resultsForDownload = action.payload;
    },
    setUploadedFileName: (state, action) => {
      state.uploadedFileName = action.payload;
    },
    setResultsReceived: (state, action) => {
      state.resultsReceived = action.payload;
    },
    setSmartPaperFlowData: (state, action) => {
      state.smartPaperFlowData = action.payload;
    },
  },
});

export const {
  setUploadStateSmartPaperFlow,
  setJobIdSmartPaperFlow,
  setResultsForDownload,
  setUploadedFileName,
  setResultsReceived,
  setSmartPaperFlowData,
  setCancelled,
} = smartPaperFlowSlice.actions;

export default smartPaperFlowSlice.reducer;

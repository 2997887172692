import * as braintree from "braintree-web";
import * as dropIn from "braintree-web-drop-in";
import {
  updateActiveProducts,
  updateActiveSmartTools,
  setAllCountries,
  setStep,
  setStepSuccess,
  setIsLoading,
  setErrorPayment,
  setSummaryData,
  setEnsuredCustomer,
  setTemporaryLaigos,
  setPersonalInfo,
} from "../SubscriptionSlice";
import { t } from "i18next";
import { _axios } from "../axiosConfig";

export const getActiveProducts = () => {
  return async (dispatch) => {
    const getActiveProductsFunction = async () => {
      const response = await _axios.get("/api/Accounting/v1/Products/Active", {
        withCredentials: false,
      });

      const data = await response;

      return data;
    };

    try {
      let activeProductsData = await getActiveProductsFunction();
      dispatch(updateActiveProducts(activeProductsData.data));
    } catch (error) {
      console.error("Error : ", error);
      console.error("Error message: ", error.message);
      console.error("Error stack: ", error.stack);
      if (error.response) {
        console.error("Error data: ", error.response.data);
        console.error("Error status: ", error.response.status);
        console.error("Error headers: ", error.response.headers);
      }
    }
  };
};

export const getActiveSmartTools = () => {
  return async (dispatch) => {
    const getActiveSmartToolsFunction = async () => {
      const response = await _axios.get(
        "/api/Accounting/v1/SmartTools/Active",
        {
          withCredentials: false,
        }
      );

      const data = await response;
      return data;
    };

    try {
      let activeSmartToolsData = await getActiveSmartToolsFunction();
      dispatch(updateActiveSmartTools(activeSmartToolsData));
    } catch (error) {
      console.error("Error : ", error);
      console.error("Error message: ", error.message);
      console.error("Error stack: ", error.stack);
      if (error.response) {
        console.error("Error data: ", error.response.data);
        console.error("Error status: ", error.response.status);
        console.error("Error headers: ", error.response.headers);
      }
    }
  };
};

export const getAllCountries = () => {
  return async (dispatch) => {
    const getAllCountriesFunction = async () => {
      const response = await _axios.get("/api/Accounting/v1/Countries/Active", {
        withCredentials: false,
      });

      const data = await response;
      return data;
    };

    try {
      let allCountriesData = await getAllCountriesFunction();
      dispatch(setAllCountries(allCountriesData));
    } catch (error) {
      console.error("Error : ", error);
      console.error("Error message: ", error.message);
      console.error("Error stack: ", error.stack);
      if (error.response) {
        console.error("Error data: ", error.response.data);
        console.error("Error status: ", error.response.status);
        console.error("Error headers: ", error.response.headers);
      }
    }
  };
};

export const createOrder = (summaryData) => {
  return async (dispatch) => {
    try {
      const response = await _axios.post(
        "/api/Accounting/v1/Orders",
        summaryData
      );
      const clientSecret = response.data.clientSecret;

      if (typeof clientSecret !== "string") {
        throw new Error("Invalid client secret received from server");
      }

      dispatch(setStep(2));
      dispatch(setStepSuccess(2));
      dispatch(getActiveCredits());

      return { data: { clientSecret } };
    } catch (error) {
      console.error("Error creating order:", error);
      throw error;
    }
  };
};

export const createPaymentMethod = (
  formRef,
  dropinContainerRef,
  deviceDataRef,
  nonceRef,
  props,
  i18n,
  customerID
) => {
  return (dispatch) => {
    _axios
      .get("/api/Accounting/v1/Customers/" + customerID + "/ClientToken", {
        withCredentials: false,
      })
      .then((response) => {
        const token = response.data;
        braintree.client.create(
          {
            authorization: token,
          },
          function (err, clientInstance) {
            braintree.dataCollector.create(
              {
                client: clientInstance,
              },
              function (err, dataCollectorInstance) {
                if (err) {
                  return;
                }
                deviceDataRef.current.value =
                  dataCollectorInstance.rawDeviceData.correlation_id;
              }
            );
          }
        );

        dropIn.create(
          {
            container: dropinContainerRef.current,
            authorization: token,
            paypal: {
              flow: "vault",
            },
            locale: i18n.language,
          },
          (error, dropinInstance) => {
            if (error) {
              dispatch(
                setErrorPayment({
                  isError: true,
                  errorPaymentMessage: t(
                    "Subscription_Payment_Error_Braintree"
                  ),
                })
              );
              console.error(error);
            }

            formRef.current.addEventListener("submit", (event) => {
              event.preventDefault();

              dropinInstance.requestPaymentMethod((error, payload) => {
                if (error) {
                  dispatch(
                    setErrorPayment({
                      isError: true,
                      errorPaymentMessage: t(
                        "Subscription_Payment_Error_Braintree"
                      ),
                    })
                  );
                  console.error(error);
                }

                nonceRef.current.value = payload.nonce;
                const data = new URLSearchParams();
                data.append("payment_method_nonce", payload.nonce);
                data.append("deviceData", deviceDataRef.current.value);

                dispatch(setIsLoading(true));
                let deviceData = deviceDataRef.current.value;

                dispatch(setIsLoading(false));
                dispatch(setSummaryData([payload.nonce, deviceData, props]));
                dispatch(
                  setErrorPayment({
                    isError: false,
                    errorPaymentMessage: "",
                  })
                );

                dispatch(setStep(3));
              });
            });
          }
        );
      })
      .catch((error) => {
        console.error("error in braintree");
        console.error(error);
        handlePaymentError(error, dispatch, true);
      });
  };
};

export const handlePaymentError = (error, dispatch, showModal) => {
  console.error("Error message: ", error.message);
  console.error("Error stack: ", error.stack);
  if (error.response) {
    console.error("Error data: ", error.response.data);
    console.error("Error status: ", error.response.status);
    console.error("Error headers: ", error.response.headers);
  }
  console.error(error);
  dispatch(setIsLoading(false));
  if (showModal) {
    dispatch(
      setErrorPayment({
        isError: false,
        errorPaymentMessage: "",
      })
    );
  }
  dispatch(
    setErrorPayment({
      isError: true,
      errorPaymentMessage: t("Subscription_Payment_Error_Braintree"),
    })
  );
};
export const ensureCustomer = (customerID) => {
  return async (dispatch) => {
    const ensureCustomerFunction = async () => {
      const response = await _axios.post(
        `/api/Accounting/v1/Customers/${customerID}/Ensure`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      return response;
    };

    try {
      const newData = await ensureCustomerFunction();
      dispatch(setEnsuredCustomer(true));
      dispatch(setPersonalInfo(newData.data));

      dispatch(
        setErrorPayment({
          isError: false,
          errorPaymentMessage: "",
        })
      );
    } catch (error) {
      dispatch(setEnsuredCustomer(false));
      dispatch(
        setErrorPayment({
          isError: true,
          errorPaymentMessage: t("Subscription_Ensure_Error"),
        })
      );
      console.error("Error : ", error);
      console.error("Error message: ", error.message);
      console.error("Error stack: ", error.stack);
      if (error.response) {
        console.error("Error data: ", error.response.data);
        console.error("Error status: ", error.response.status);
        console.error("Error headers: ", error.response.headers);
      }
    }
  };
};

export const updateCustomer = (customerID, data) => {
  return async (dispatch) => {
    try {
      const response = await _axios.put(
        `/api/Accounting/v1/Customers/${customerID}`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return response;
    } catch (error) {
      dispatch(setEnsuredCustomer(false));
      dispatch(
        setErrorPayment({
          isError: true,
          errorPaymentMessage: t("Subscription_Update_Error"),
        })
      );
      console.error("Error : ", error);
      console.error("Error message: ", error.message);
      console.error("Error stack: ", error.stack);
      if (error.response) {
        console.error("Error data: ", error.response.data);
        console.error("Error status: ", error.response.status);
        console.error("Error headers: ", error.response.headers);
      }
      throw error;
    }
  };
};

var endDate = new Date();
endDate.setDate(endDate.getDate() + 1);

export const getActiveCredits = () => {
  return async (dispatch) => {
    const getActiveCreditsFunction = async () => {
      const response = await _axios.get("/api/Accounting/v1/Credits/Active", {
        withCredentials: false,
      });

      const data = await response;

      return data;
    };

    try {
      const response = await getActiveCreditsFunction();
      dispatch(setTemporaryLaigos(response.data));
      dispatch(setIsLoading(false));
    } catch (error) {
      console.error("Error : ", error);
      console.error("Error message: ", error.message);
      console.error("Error stack: ", error.stack);
      if (error.response) {
        console.error("Error data: ", error.response.data);
        console.error("Error status: ", error.response.status);
        console.error("Error headers: ", error.response.headers);
      }
    }
  };
};

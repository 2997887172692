import { createSlice } from "@reduxjs/toolkit";

const smartICRSlice = createSlice({
  name: "smartICR",
  initialState: {
    uploadState: 1,
    icrJobId: 0,
    resultsForDownload: 0,
    uploadedFileName: "",
    resultsReceived: false,
    smartICRData: {},
  },
  reducers: {
    setUploadStateSmartICR: (state, action) => {
      state.uploadState = action.payload;
    },
    setJobIdSmartICR: (state, action) => {
      state.icrJobId = action.payload;
    },
    setResultsForDownload: (state, action) => {
      state.resultsForDownload = action.payload;
    },
    setUploadedFileName: (state, action) => {
      state.uploadedFileName = action.payload;
    },
    setResultsReceived: (state, action) => {
      state.resultsReceived = action.payload;
    },
    setSmartICRData: (state, action) => {
      state.smartICRData = action.payload;
    },
  },
});

export const {
  setUploadStateSmartICR,
  setJobIdSmartICR,
  setResultsForDownload,
  setUploadedFileName,
  setResultsReceived,
  setSmartICRData,
} = smartICRSlice.actions;

export default smartICRSlice.reducer;

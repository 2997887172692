import { createSlice } from "@reduxjs/toolkit";

const UsersSlice = createSlice({
  name: "Users",
  initialState: {
    users: 0,
    newUsers: 0,
    newUserDelete: 0,
    userRoles: null,
    updateUserSuccess: null,
    laigoUsers: null,
    groupId: {},
    userId: 0,
    user: 0,
    userProfile: false,
    groups: [],
    externalSource: "",
    subscriptionNames: [],
    availableLaigos: 0,
  },
  reducers: {
    setGetTotalConsumptionsErrorState: (state, action) => {
      state.isTotalConsumptionsInErrorState = true;
    },

    setUsers: (state, action) => {
      state.users = action.payload;
    },

    setUserById: (state, action) => {
      state.user = action.payload;
    },

    setLaigoUsers: (state, action) => {
      state.laigoUsers = action.payload;
    },

    setNewUsers: (state, action) => {
      state.newUsers = action.payload;
    },

    deleteUser: (state, action) => {
      state.newUserDelete = action.payload;
    },

    setUserRoles: (state, action) => {
      state.userRoles = action.payload;
    },

    setUpdateUserSuccess: (state, action) => {
      state.updateUserSuccess = action.payload;
    },

    setGroupId: (state, action) => {
      state.groupId = action.payload;
    },

    setUserProfile: (state, action) => {
      state.userId = action.payload[0];
      state.userProfile = action.payload[1];
    },
    setGroups: (state, action) => {
      state.groups = action.payload;
    },
  },
});

export const {
  setTotalConsumptions,
  setUsers,
  setUserById,
  setUserRoles,
  setUpdateUserSuccess,
  setNewUsers,
  deleteUser,
  setLaigoUsers,
  setGroupId,
  setUserProfile,
  setGroups,
} = UsersSlice.actions;

export default UsersSlice.reducer;

import React, { useState } from "react";
import { useSelector } from "react-redux";
import { HiMenuAlt3 } from "react-icons/hi";
import { Link } from "react-router-dom";
import { t } from "i18next";

import subscriptionIcon from "../../assets/icons/subscriptionIcon.svg";
import smartTablePageIcon from "../../assets/icons/smartTableIcon.svg";
import smartInvoicePageIcon from "../../assets/icons/smartInvoiceIcon.svg";
import smartICRPageIcon from "../../assets/icons/smartICRIcon.svg";
import userRolesPageIcon from "../../assets/icons/userRolesIcon.svg";
import adminPageIcon from "../../assets/icons/userIcon.svg";
import apikeysIcon from "../../assets/icons/apiPageIcon.svg";

function MobileMenu() {
  const isAuth = useSelector((state) => state.authStatus.isLoggedIn);
  const resourceAccess = useSelector(
    (state) => state.authStatus.resourceAccess
  );
  const [open, setOpen] = useState(true);

  const menuItems = [
    {
      path: "/smartTable",
      name: t("Menu_Table"),
      icon: smartTablePageIcon,
      roles: ["smartTable"],
    },
    {
      path: "/smartPaperFlow",
      name: t("SmartPaperFlow"),
      icon: smartTablePageIcon,
      roles: ["smartPaperFlow"],
    },
    {
      path: "/smartInvoice",
      name: t("Invoice_Extraction"),
      icon: smartInvoicePageIcon,
      roles: ["smartInvoice"],
    },
    {
      path: "/smartICR",
      name: t("Text_Extraction"),
      icon: smartICRPageIcon,
      roles: ["smartICR"],
    },
    {
      path: "/smartCustoms",
      name: t("smartCustoms"),
      icon: smartInvoicePageIcon,
      roles: ["smartCustoms"],
    },
    {
      path: "/userRoles",
      name: t("Roles"),
      icon: userRolesPageIcon,
      roles: ["laigoAdmin"],
    },
    {
      path: "/subscription",
      name: t("Menu_subscription"),
      icon: subscriptionIcon,
      roles: ["laigoAdmin"],
    },
    {
      path: "/subscription",
      name: t("Menu_subscription"),
      icon: subscriptionIcon,
      roles: ["laigoAdmin", "subscription"],
    },
    {
      path: "/apikeys",
      name: t("Menu_ApiKeys"),
      icon: apikeysIcon,
      roles: ["smartAPIs", "laigoAdmin"],
    },
    {
      path: "/Users",
      name: t("Menu_Mgmtdashboard"),
      icon: adminPageIcon,
      roles: ["laigoAdmin"],
    },
  ].filter(
    (item) => isAuth && item.roles.some((role) => resourceAccess.includes(role))
  );

  return (
    <nav
      className={`${
        open ? "" : "bg-black"
      } lg:mt-12 text-white lg:w-screen p-4 md:hidden lg:hidden`}
    >
      <HiMenuAlt3
        size={26}
        className="cursor-pointer ml-auto"
        onClick={() => setOpen(!open)}
      />

      <ul
        className={`${
          open ? "hidden" : ""
        } duration-500 absolute w-64 mt-4 right-0 bg-black p-4 transition-all`}
      >
        {menuItems.map((item, index) => (
          <li key={index} className="py-3 flex">
            <img src={item.icon} alt="" className="mt-1 mr-2 w-4" />
            <Link to={item.path} className="text-white">
              {item.name}
            </Link>
          </li>
        ))}
      </ul>
    </nav>
  );
}

export default MobileMenu;

import { createSlice } from "@reduxjs/toolkit";

const smartToolsSlice = createSlice({
  name: "smartTools",
  initialState: {
    uploadState: 1,
    jobID: null,
    resultsData: {},
    uploadedFileNameSmartTools: "",
  },
  reducers: {
    setUploadState: (state, action) => {
      state.uploadState = action.payload;
    },
    setJobId: (state, action) => {
      state.jobID = action.payload;
    },
    setResultsData: (state, action) => {
      state.resultsData = action.payload;
    },
    setUploadedFileNameSmartTools: (state, action) => {
      state.uploadedFileNameSmartTools = action.payload;
    },
  },
});

export const {
  setUploadState,
  setJobId,
  setResultsData,
  setUploadedFileNameSmartTools,
} = smartToolsSlice.actions;

export default smartToolsSlice.reducer;

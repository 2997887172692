import axios from "axios";
import KeycloakService from "../pages/KeycloakService";
import { initOptions } from "../KeycloakConfig";

const createAxiosInstance = (baseURL) => {
  const instance = axios.create({ baseURL });

  instance.interceptors.request.use(
    async (config) => {
      if (KeycloakService.isLoggedIn()) {
        const isTokenExpired = KeycloakService.isTokenExpired();
        if (isTokenExpired) {
          await KeycloakService.updateToken();
        }
        config.headers.Authorization = `Bearer ${KeycloakService.getToken()}`;
      }
      return config;
    },
    (error) => Promise.reject(error)
  );

  return instance;
};

const _axios = createAxiosInstance(window.serverUrl);
const _axios_keycloak = createAxiosInstance(initOptions.url);

const CancelToken = axios.CancelToken;
const source = CancelToken.source();

export { _axios, _axios_keycloak, CancelToken, source, axios };

import { createSlice } from "@reduxjs/toolkit";

const defaultSettingsSlice = createSlice({
  name: "defaultSettings",
  initialState: {
    defaultLanguage: "en",
    sidebarOpen: false,
  },
  reducers: {
    setDefaultLanguage: (state, action) => {
      state.defaultLanguage = action.payload;
    },
    setSidebarOpen: (state, action) => {
      state.sidebarOpen = action.payload;
    },
  },
});

export const { setDefaultLanguage, setSidebarOpen } =
  defaultSettingsSlice.actions;

export default defaultSettingsSlice.reducer;

import { createSlice } from "@reduxjs/toolkit";

const subscriptionSlice = createSlice({
  name: "subscription",
  initialState: {
    temporaryLaigos: 0,
    status: "",
    isLoading: false,
    activeProductsData: {},
    activeSmartTools: {},
    ensuredCustomer: false,
    errorPayment: {
      isError: false,
      errorPaymentMessage: "",
    },
    personalInfo: {},
    creditCardInfo: {
      cardOwner: "",
      cardNumber: "",
      expiryDate: "",
      cvc: "",
    },
    acceptTerms: false,
    errorMessage: "",
    step: 1,
    stepSuccess: 1,
    allCountries: {},
    summaryData: {
      NonceData: "",
      DeviceData: "",
      Items: [
        {
          ProductId: "",
          Quantity: "",
        },
      ],
    },
  },
  reducers: {
    setTemporaryLaigos: (state, action) => {
      state.temporaryLaigos = action.payload;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    updateActiveProducts: (state, action) => {
      state.activeProductsData = action.payload;
    },
    updateActiveSmartTools: (state, action) => {
      state.activeSmartTools = action.payload;
    },
    setErrorMessage: (state, action) => {
      state.errorMessage = action.payload;
    },
    setErrorPayment: (state, action) => {
      state.errorPayment = action.payload;
    },
    setStep: (state, action) => {
      state.step = action.payload;
    },
    setStepSuccess: (state, action) => {
      state.stepSuccess = action.payload;
    },
    setPersonalInfo: (state, action) => {
      state.personalInfo = action.payload;
    },
    setAcceptTerms: (state, action) => {
      state.acceptTerms = action.payload;
    },
    setAllCountries: (state, action) => {
      state.allCountries = action.payload;
    },
    setSummaryData: (state, action) => {
      state.summaryData = {
        NonceData: action.payload[0],
        DeviceData: action.payload[1],
        Items: [
          {
            ProductId: action.payload[2],
            Quantity: 1,
          },
        ],
      };
    },
    setOrderData: (state, action) => {
      state.orderData = action.payload;
    },
    setEnsuredCustomer: (state, action) => {
      state.ensuredCustomer = action.payload;
    },
  },
});

export const {
  setTemporaryLaigos,
  updateActiveProducts,
  updateActiveSmartTools,
  setErrorMessage,
  setErrorPayment,
  setStep,
  setStepSuccess,
  setPersonalInfo,
  setAcceptTerms,
  setAllCountries,
  setSummaryData,
  setIsLoading,
  setEnsuredCustomer,
} = subscriptionSlice.actions;

export default subscriptionSlice.reducer;

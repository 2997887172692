import React, { useState, forwardRef } from "react";
import "../../assets/css/components/UI/Input.scss";
import { useTranslation } from "react-i18next";
import Tooltip from "./Tooltip";

const Input = forwardRef(
  (
    {
      tooltip,
      validateTrigger,
      fieldError,
      tooltipIcon,
      isSubmitted,
      dataChanged,
      isSmartPaperFlow = false,
      fieldName,
      ...props
    },
    ref
  ) => {
    const { t } = useTranslation();
    const [touched, setTouched] = useState(false);

    const handleBlur = () => {
      setTouched(true);
    };

    const isFieldEmpty = !props.value;
    const showError =
      props.required &&
      (touched || validateTrigger || fieldError) &&
      isFieldEmpty;
    const showFieldError = !!fieldError && !isFieldEmpty;

    const inputClass =
      isSubmitted && dataChanged
        ? "inputSubmitted"
        : dataChanged
        ? "inputChanged"
        : "";

    const inputSizeClass = isSmartPaperFlow
      ? fieldName === "Summary"
        ? "customTextarea-large"
        : fieldName === "DetailedSummary"
        ? "customTextarea-xlarge"
        : ""
      : "";

    return (
      <div
        className={`${props.wrapperClassName} inputValue flex flex-col position-relative`}
      >
        <label className={`${props.labelClass} labelValue`}>
          {t(props.inputLabel)}
          {tooltip && (
            <Tooltip
              header={props.tooltipHeader}
              content={props.tooltipContent}
              icon={tooltipIcon}
              tooltipIcon={tooltipIcon}
            />
          )}
        </label>

        {fieldName === "Summary" || fieldName === "DetailedSummary" ? (
          <textarea
            className={`customTextarea ${props.className} ${
              props.parentDivClass
            } ${
              showError || showFieldError ? "errorInput" : ""
            } ${inputClass} ${inputSizeClass}`}
            onClick={props.onClick}
            onBlur={handleBlur}
            disabled={props.disabled}
            required={props.required}
            onChange={props.onChange}
            id={props.id}
            value={props.value}
            name={props.name}
            ref={ref}
            placeholder={props.placeholder}
            readOnly={props.readOnly}
          />
        ) : (
          <input
            className={`customInput ${props.className} ${
              props.parentDivClass
            } ${
              showError || showFieldError ? "errorInput" : ""
            } ${inputClass} ${inputSizeClass}`}
            onClick={props.onClick}
            onBlur={handleBlur}
            disabled={props.disabled}
            required={props.required}
            onChange={props.onChange}
            onKeyDown={props.onKeyDown}
            type={props.inputType}
            id={props.id}
            value={props.value}
            checked={props.checked === true}
            onInvalid={(e) => e.preventDefault()}
            name={props.name}
            ref={ref}
            placeholder={props.placeholder}
            defaultValue={props.defaultValue}
            readOnly={props.readOnly}
          />
        )}

        {props.error && (
          <div>
            <span className="text-red-500">{t(props.errorText)}</span>
          </div>
        )}
        {showFieldError && (
          <div className="mandatoryField">
            {props.showCustomErrorText ? props.errorText : t("Field_Error")}
          </div>
        )}
        {showError && !showFieldError && (
          <div className="mandatoryField">{t("Mandatory_Field")}</div>
        )}
      </div>
    );
  }
);

export default Input;

import { createSlice } from "@reduxjs/toolkit";

const smartCustomsSlice = createSlice({
  name: "smartCustoms",
  initialState: {
    uploadState: 1,
    scJobId: 0,
    resultsForDownload: 0,
    uploadedFileName: "",
    resultsReceived: false,
    customsData: false,
  },
  reducers: {
    setUploadStateSmartCustoms: (state, action) => {
      state.uploadState = action.payload;
    },
    setJobIdSmartCustoms: (state, action) => {
      state.scJobId = action.payload;
    },
    setResultsForDownload: (state, action) => {
      state.resultsForDownload = action.payload;
    },
    setUploadedFileName: (state, action) => {
      state.uploadedFileName = action.payload;
    },
    setResultsReceived: (state, action) => {
      state.resultsReceived = action.payload;
    },
    setSmartCustomsData: (state, action) => {
      state.customsData = action.payload;
    },
  },
});

export const {
  setUploadStateSmartCustoms,
  setJobIdSmartCustoms,
  setResultsForDownload,
  setUploadedFileName,
  setResultsReceived,
  setSmartCustomsData,
} = smartCustomsSlice.actions;

export default smartCustomsSlice.reducer;

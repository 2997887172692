// import LoadingGif from "../assets/images/loadingAPI.gif";
// import laigoLogo from "../assets/icons/laigoLogo.svg";
import loadingGif from "../assets/images/loadingAPI.gif";
import classes from "../assets/css/pages/Loading.module.scss";
function Loading(props) {
  return (
    <div
      className={` fixed inset-0 z-[5] h-screen flex flex-col items-center justify-center bg-[#31a5be] bg-opacity-50 backdrop-blur transition-opacity `}
    >
      <img src={loadingGif} alt="Loading..." className="w-[86px] h-[86px]" />
      {props.cancelBtn && (
        <div
          onClick={props.onClick}
          text="Cancel"
          className={classes.cancelBtn}
        />
      )}
    </div>
  );
}
export default Loading;

import { t } from "i18next";
import { setCredentials } from "../ApiPageSlice";
import {
  setClientCredentialsExists,
  setErrorMessage,
  setLoading,
  setGetTotalConsumptionsErrorState,
  setGetTotalUserCountsErrorState,
  setTotalConsumptions,
  setTotalUserCounts,
} from "../ApiPageSlice";
import { _axios } from "../axiosConfig";
import axios from "axios";
import moment from "moment";
import { updateRefreshToken } from "../AuthSlice";
import { showNotification } from "../NotificationSlice";

export const getClientCredentials = () => {
  return async (dispatch) => {
    dispatch(setLoading(true));
    const getClientFunction = async () => {
      const response = await _axios.get("/api/Configuration/v1/ApiKeys", {
        headers: {
          "Content-Type": "application/json",
        },
      });

      const data = await response;

      return data;
    };

    try {
      const newClientData = await getClientFunction();
      dispatch(setCredentials(newClientData.data));
      dispatch(setClientCredentialsExists(true));
      dispatch(setLoading(false));
      dispatch(setErrorMessage(false));
    } catch (error) {
      dispatch(setClientCredentialsExists(false));
      dispatch(setLoading(false));
      dispatch(setCredentials([]));

      if (error.response && error.response.status === 401) {
        // dispatch(logout());
      } else {
        console.error("Error : ", error);
      }
      return false;
    }
  };
};
export const removeCredentials = (uuid) => {
  return async (dispatch) => {
    dispatch(setLoading(true));
    const removeCredentialsFunction = async () => {
      const response = await _axios.delete(
        "/api/Configuration/v1/ApiKeys/" + uuid,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response;
      return data;
    };

    try {
      await removeCredentialsFunction();
      dispatch(setErrorMessage(false));
      dispatch(setLoading(false));
      dispatch(getClientCredentials());
    } catch (error) {
      console.error("Error : ", error);
      console.error("Error message: ", error.message);
      console.error("Error stack: ", error.stack);
      if (error.response) {
        console.error("Error data: ", error.response.data);
        console.error("Error status: ", error.response.status);
        console.error("Error headers: ", error.response.headers);
      }
      // dispatch(setErrorMessage(t("ApiPage_ErrorMessage")));
      // dispatch(setClientCredentialsExists(false));
      dispatch(setLoading(false));
      return false;
    }
  };
};

export const getNewClientCredentials = (data2) => {
  return async (dispatch) => {
    dispatch(setLoading(true));

    const getNewClientFunction = async () => {
      const response = await _axios.post("/api/Configuration/v1/ApiKeys/", {
        headers: {
          "Content-Type": "application/json",
        },
        clientName: data2,
      });

      const data = await response;
      return data;
    };

    try {
      const newClientData = await getNewClientFunction();
      dispatch(setClientCredentialsExists(true));
      dispatch(setErrorMessage(false));
      dispatch(setLoading(false));
      dispatch(getClientCredentials());
      dispatch(setCredentials(newClientData.data));
    } catch (error) {
      console.error("error : ", error);
      console.error("Error message: ", error.message);
      console.error("Error stack: ", error.stack);
      if (error.response) {
        if (error.response.data === "API Key already exists.") {
          dispatch(
            showNotification({
              id: "uploadError",
              message: "Apipage_Api_Exists",
              type: "Error",
            })
          );
        } else {
          dispatch(
            showNotification({
              id: "uploadError",
              message: "General_Error",
              type: "Error",
            })
          );
        }
        console.error("Error data: ", error.response.data);
        console.error("Error status: ", error.response.status);
        console.error("Error headers: ", error.response.headers);
      }
      dispatch(setErrorMessage(t("ApiPage_ErrorMessage")));
      dispatch(setClientCredentialsExists(false));
      dispatch(setLoading(false));
      return false;
    }
  };
};

export const resetClientCredentials = (clientId) => {
  return async (dispatch) => {
    dispatch(setLoading(true));
    const resetClientCredentialsFunction = async () => {
      const response = await _axios.put(
        "/api/Configuration/v1/ApiKeys/" + clientId + "/ResetSecret",
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const data = await response;
      return data;
    };
    try {
      const resettedClientData = await resetClientCredentialsFunction();
      dispatch(setClientCredentialsExists(true));
      dispatch(setErrorMessage(false));
      dispatch(setLoading(false));
      dispatch(getClientCredentials());
      dispatch(setCredentials(resettedClientData.data));
    } catch (error) {
      console.error("Error : ", error);
      console.error("Error message: ", error.message);
      console.error("Error stack: ", error.stack);
      if (error.response) {
        console.error("Error data: ", error.response.data);
        console.error("Error status: ", error.response.status);
        console.error("Error headers: ", error.response.headers);
      }
      dispatch(setErrorMessage(t("ApiPage_ErrorMessage")));
      dispatch(setClientCredentialsExists(false));
      dispatch(setLoading(false));
      return false;
    }
  };
};

export const checkWizardCompletion = () => {
  return async (dispatch) => {
    const checkWizardCompletionFunction = async () => {
      const response = await _axios.get(
        "/api/Accounting/v1/InitWizard/Completed"
      );

      const data = await response;
      return data.data;
    };
    try {
      const data = await checkWizardCompletionFunction();
      return data;
    } catch (error) {
      console.error("Error : ", error);
      console.error("Error message: ", error.message);
      console.error("Error stack: ", error.stack);
      if (error.response) {
        console.error("Error data: ", error.response.data);
        console.error("Error status: ", error.response.status);
        console.error("Error headers: ", error.response.headers);
      }
      return false;
    }
  };
};

export const completeWizard = () => {
  return async (dispatch) => {
    const completeWizardFunction = async () => {
      const response = await _axios.post(
        "/api/Accounting/v1/InitWizard/Completed",
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response;
      return data;
    };
    try {
      await completeWizardFunction();
    } catch (error) {
      console.error("Error : ", error);
      console.error("Error message: ", error.message);
      console.error("Error stack: ", error.stack);
      if (error.response) {
        console.error("Error data: ", error.response.data);
        console.error("Error status: ", error.response.status);
        console.error("Error headers: ", error.response.headers);
      }
      return false;
    }
  };
};

export const getTotalConsumptions = (tokenAccess) => {
  return async (dispatch) => {
    var intervalDays = 7;
    var startDate = moment().add(-1 * intervalDays + 1, "days");
    var endDate = new Date();
    var startDateString = moment(startDate).format("YYYY-MM-DD") + "Z";
    var endDateString = moment(endDate).format("YYYY-MM-DD") + "Z";

    const getTotalConsumptionsFunction = async () => {
      const response = await axios.get(
        window.serverUrl +
          "/api/ManagementDashboard/v1/Consumptions/ByIntervalGrouped?startDateUTC=" +
          startDateString +
          "&endDateUTC=" +
          endDateString,
        {
          headers: {
            authorization: "Bearer " + tokenAccess,
          },
        }
      );

      const data = await response;
      return data;
    };

    try {
      const totalConsumptionData = await getTotalConsumptionsFunction();
      dispatch(
        setTotalConsumptions({
          data: totalConsumptionData.data,
          startDate: startDate,
          endDate: endDate,
          interval: intervalDays,
        })
      );
    } catch (error) {
      console.error("Error message: ", error.message);
      console.error("Error stack: ", error.stack);
      if (error.response) {
        console.error("Error data: ", error.response.data);
        console.error("Error status: ", error.response.status);
        console.error("Error headers: ", error.response.headers);
      }
      dispatch(setGetTotalConsumptionsErrorState());
    }
  };
};

export const getTotalUserCounts = (tokenAccess) => {
  return async (dispatch) => {
    var intervalDays = 7;
    var startDate = moment().add(-1 * intervalDays + 1, "days");
    var endDate = new Date();
    var startDateString = moment(startDate).format("YYYY-MM-DD") + "Z";
    var endDateString = moment(endDate).format("YYYY-MM-DD") + "Z";

    const getTotalUserCountsFunction = async () => {
      const response = await axios.get(
        window.serverUrl +
          "/api/ManagementDashboard/v1/UserCount/ByIntervalGrouped?startDateUTC=" +
          startDateString +
          "&endDateUTC=" +
          endDateString,
        {
          headers: {
            authorization: "Bearer " + tokenAccess,
          },
        }
      );

      const data = await response;
      return data;
    };

    try {
      const totalUserData = await getTotalUserCountsFunction();
      dispatch(
        setTotalUserCounts({
          data: totalUserData.data,
          startDate: startDate,
          endDate: endDate,
          interval: intervalDays,
        })
      );
    } catch (error) {
      console.error("Error message: ", error.message);
      console.error("Error stack: ", error.stack);
      if (error.response) {
        console.error("Error data: ", error.response.data);
        console.error("Error status: ", error.response.status);
        console.error("Error headers: ", error.response.headers);
      }
      dispatch(setGetTotalUserCountsErrorState());

      if (error.request.status === 401) {
        dispatch(updateRefreshToken());
      }
    }
  };
};

import { createSlice } from "@reduxjs/toolkit";
import { initOptions } from "../KeycloakConfig";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    isLoggedIn: false,
    isLaigoAdmin: false,
    user: null,
    tokenAccess: "",
    tokenUpdated: false,
    expiryPeriod: "",
    errorKeycloak: false,
    isLoggingOut: false,
    idToken: "",
    languageChangedManually: false,
    resourceAccess: [],
  },
  reducers: {
    setIsLoggingOut: (state, action) => {
      state.isLoggingOut = action.payload;
    },
    setLanguageChangedManually(state, action) {
      state.languageChangedManually = action.payload;
    },
    login: (state, action) => {
      state.isLoggedIn = true;
      state.user = action.payload;
      state.userID = action.payload.tokenParsed.sub;
      state.tokenAccess = action.payload.token;
      state.idToken = action.payload.idToken;
      state.keycloakInfo = action;
      state.tokenUpdated = true;
      state.resourceAccess =
        action.payload.resourceAccess[initOptions.clientId]?.roles || [];
      var tokenExpiresTime = action.payload.tokenParsed.exp;
      var tokenIssuedTime = action.payload.tokenParsed.iat;
      var tokenExpiryPeriod = (tokenExpiresTime - tokenIssuedTime) / 60;

      var devModeExpiryPeriod = window.tokenExpireTime * 1000;

      if (action.payload.tokenParsed.laigoGroups) {
        action.payload.tokenParsed.laigoGroups.forEach((currentGroup) => {
          if (currentGroup === "LaigoAdmins") {
            state.isLaigoAdmin = true;
          }
        });
      }

      if (1 < 0) {
        state.expiryPeriod = devModeExpiryPeriod;
      } else {
        state.expiryPeriod = tokenExpiryPeriod;
      }
    },
    setTokenAccess: (state, action) => {
      state.tokenUpdated = true;
      state.tokenAccess = action.payload;
    },
    logout: (state) => {
      state.isLoggedIn = false;
      state.isLaigoAdmin = false;
      state.user = null;
      state.tokenAccess = "";
      state.tokenUpdated = false;
      state.expiryPeriod = "";
      state.errorKeycloak = false;
    },
    updateRefreshToken: (state, action) => {
      state.tokenUpdated = true;
      state.tokenAccess = action.payload.token;
      state.idToken = action.payload.idToken;
      state.tokenExpiresTime = action.payload.tokenExpiresTime;
      state.tokenIssuedTime = action.payload.tokenIssuedTime;
      state.tokenExpiryPeriod = action.payload.tokenExpiryPeriod;
    },

    setErrorKeycloak: (state, action) => {
      state.errorKeycloak = action.payload;
    },
  },
});

export const {
  login,
  logout,
  setTokenAccess,
  updateRefreshToken,
  setErrorKeycloak,
  setIsLoggingOut,
  setLanguageChangedManually,
} = authSlice.actions;

export default authSlice.reducer;

import { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { logout, setLanguageChangedManually } from "../../store/AuthSlice";
import { reset } from "../../store/LaigosSlice";
import laigoLogo from "../../assets/icons/laigoLogo.svg";
import classes from "../../assets/css/components/base/Header.module.scss";
import dropDownSign from "../../assets/icons/dropDownAccountMenuIcon.svg";
import Button from "../UI/Button";
import { initOptions } from "../../KeycloakConfig";
import { setUserProfile } from "../../store/Users";
import { Link } from "react-router-dom";

function Header() {
  const dispatch = useDispatch();
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [isOpenLang, setIsOpenLang] = useState(false);
  const { t, i18n } = useTranslation();
  const keycloakExisting = useSelector(
    (state) => state.authStatus.user.tokenParsed
  );

  const idToken = useSelector((state) => state.authStatus.idToken);

  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      const dropdownContainer = document.querySelector(
        `.${classes.dropdownContainer}`
      );

      if (
        dropdownVisible &&
        !event.target.closest(`.${classes.avatarIcon}`) &&
        dropdownContainer &&
        !dropdownContainer.contains(event.target)
      ) {
        setDropdownVisible(false);
        setIsOpenLang(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownVisible]);

  const userProfileData = (userId) => {
    dispatch(setUserProfile([userId.sub, true]));
    dispatch(reset());
  };

  function logOut() {
    sessionStorage.removeItem("isNewLogin");
    dispatch(setLanguageChangedManually(false));
    localStorage.removeItem("userSelectedLanguage");
    localStorage.setItem("languageChangedManually", "false");

    let redirectUri = `${window.location.origin.toString()}`;

    let KEYCLOAK_LOGOUT_URL = `${initOptions.url}/realms/SmartTools/protocol/openid-connect/logout`;
    const logoutURL = `${KEYCLOAK_LOGOUT_URL}?id_token_hint=${idToken}&client_id=${initOptions.clientId}&post_logout_redirect_uri=${redirectUri}&prompt=none`;

    window.location.href = logoutURL;

    dispatch(logout());
  }

  const changeLang = (value) => {
    i18n.changeLanguage(value);
    localStorage.setItem("userSelectedLanguage", value);
    localStorage.setItem("languageChangedManually", "true");
    dispatch(setLanguageChangedManually(true));
  };

  const handleAvatarClick = () => {
    setDropdownVisible((prevVisible) => !prevVisible);
  };

  return (
    <div className={classes.mainMenu + " fixed w-full h-12"}>
      <div className="grid grid-cols-12">
        <div className="pl-2 lg:pl-16 my-auto col-end-5 col-span-5">
          <span>
            <img className="laigoLogo" src={laigoLogo} alt=""></img>
          </span>
        </div>
        <div className=" lg:pr-16 col-start-10 col-span-3 relative">
          <div
            className={`cursor-pointer absolute right-4 lg:right-16 top-2 z-50 w-40 ${classes.avatarIcon}`}
            onClick={handleAvatarClick}
          >
            <img
              className={` top-2 right-0 absolute ${
                dropdownVisible ? "rotate-180" : ""
              }`}
              alt="avatar"
              src={dropDownSign}
            />
            <div
              className={`${classes.dropdownContainer} laigoLogo  bg-[#D9D9D9] text-[#135C6C] top-1 absolute right-6 text-center rounded-full  font-bold`}
              style={{
                width: "29px",
                height: "29px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {keycloakExisting.given_name[0]}
            </div>
          </div>

          {dropdownVisible && (
            <>
              <div
                className="fixed inset-0 z-30"
                onClick={() => setDropdownVisible(false)}
              ></div>
              <div
                ref={dropdownRef}
                className={`${classes.dropdownContainer} text-sm pt-12 max-w-64 absolute right-2 lg:right-[50px] bg-[#502E66] pl-4 pr-4 pb-4 space-y-2 text-right text-white rounded-b-lg z-40`}
              >
                <div className="text-white text-sm  mt-[5px]">
                  {keycloakExisting.name}
                </div>

                <Link
                  to={"/userprofile"}
                  onClick={() => userProfileData(keycloakExisting)}
                >
                  Profile
                </Link>

                <div className="cursor-pointer overflow-x-hidden whitespace-nowrap overflow-hidden overflow-ellipsis">
                  <span>{keycloakExisting.email} </span>
                </div>
                <div className={`relative ${isOpenLang ? "mb-1 py-1 " : ""}`}>
                  <div
                    className="cursor-pointer flex flex-row-reverse"
                    onClick={() => setIsOpenLang(!isOpenLang)}
                  >
                    <img
                      className={`transform ${isOpenLang ? "rotate-180" : ""}`}
                      src={dropDownSign}
                      alt=""
                    />{" "}
                    <span className="pr-2">{t("Header_Language")}</span>
                  </div>

                  {isOpenLang && (
                    <div className="flex flex-col space-y-1 w-full pl-3 mt-1 text-white rounded">
                      <div
                        className="cursor-pointer"
                        onClick={() => changeLang("en")}
                      >
                        {t("English")}
                      </div>
                      <div
                        className="cursor-pointer"
                        onClick={() => changeLang("de")}
                      >
                        {t("German")}
                      </div>
                      <div
                        className="cursor-pointer"
                        onClick={() => changeLang("mkd")}
                      >
                        {t("Macedonian")}
                      </div>
                    </div>
                  )}
                </div>

                <Button
                  className="text-sm primaryBtn w-full cursor-pointer"
                  onClick={logOut}
                  text={t("Header_Logout")}
                ></Button>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default Header;

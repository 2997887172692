import React, { useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import UsersSvg from "../../assets/icons/userIcon.svg";
import subscriptionIcon from "../../assets/icons/subscriptionIcon.svg";
import smartTableIcon from "../../assets/icons/smartTableIcon.svg";
import smartInvoiceIcon from "../../assets/icons/smartInvoiceIcon.svg";
import smartICRIcon from "../../assets/icons/smartICRIcon.svg";
// import userRolesIcon from "../../assets/icons/userRolesIcon.svg";
// import apikeys from "../../assets/icons/apiPageIcon.svg";
import sidebardMenuOpenIcon from "../../assets/icons/sidebardMenuOpenIcon.svg";
import "../../assets/css/components/base/SideBar.scss";
import { setSidebarOpen } from "../../store/DefaultSettings";
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { t } from "i18next";

const Sidebar = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const open = useSelector((state) => state.defaultSettings.sidebarOpen);
  const resourceAccess = useSelector(
    (state) => state.authStatus.resourceAccess
  );

  const dropdownRef = useRef(null);
  const [adminDropdownOpen, setAdminDropdownOpen] = useState(false);
  const [wasAdminDropdownOpen, setWasAdminDropdownOpen] = useState(false);

  const isAuth = useSelector((state) => state.authStatus.isLoggedIn);

  const sidebarWidthOpen = "17.5rem";
  const sidebarWidthClosed = "4rem";

  const handleSidebarClick = () => {
    if (open) {
      setWasAdminDropdownOpen(adminDropdownOpen);
      setAdminDropdownOpen(false);
    } else {
      if (wasAdminDropdownOpen) {
        setAdminDropdownOpen(true);
      }
    }
    dispatch(setSidebarOpen(!open));
  };

  const toggleAdminDropdown = () => {
    if (!open) {
      dispatch(setSidebarOpen(true));
      setAdminDropdownOpen(true);
      setWasAdminDropdownOpen(true);
    } else {
      setAdminDropdownOpen(!adminDropdownOpen);
      setWasAdminDropdownOpen(!adminDropdownOpen);
    }
  };

  const handleMenuItemClick = (menu) => {
    if (menu.isDropdownTrigger) {
      setAdminDropdownOpen(!adminDropdownOpen);
    } else if (menu.dropdownId === "adminDropdown") {
    } else {
      setAdminDropdownOpen(false);
    }
  };

  useEffect(() => {
    if (open && wasAdminDropdownOpen) {
      setAdminDropdownOpen(true);
    }
  }, [open, wasAdminDropdownOpen]);

  const hasAccessToMenu = (menuRoles) => {
    if (resourceAccess.includes("laigoAdmin")) {
      return true;
    }
    return menuRoles.some((role) => resourceAccess.includes(role));
  };

  const menus = [
    {
      className: "sidebar-menu-link",
      name: t("Text_Extraction"),
      link: "/smartICR",
      icon: smartICRIcon,
      roles: ["smartICR"],
    },
    {
      className: "sidebar-menu-link",
      name: t("Invoice_Extraction"),
      link: "/smartInvoice",
      icon: smartInvoiceIcon,
      roles: ["smartInvoice"],
    },
    {
      className: "sidebar-menu-link",
      name: t("smartCustoms"),
      link: "/smartCustoms",
      icon: smartInvoiceIcon,
      roles: ["smartCustoms"],
    },
    {
      className: "sidebar-menu-link",
      name: t("Table_Extraction"),
      link: "/smartTable",
      icon: smartTableIcon,
      roles: ["smartTable"],
    },
    {
      className: "sidebar-menu-link",
      name: t("SmartPaperFlow"),
      link: "/smartPaperFlow",
      icon: smartTableIcon,
      roles: ["smartPaperFlow"],
    },
    {
      className: "sidebar-menu-link adminDropDown",
      name: t("Menu_Administration"),
      icon: UsersSvg,
      isDropdownTrigger: true,
      dropdownId: "adminDropdown",
      roles: ["laigoAdmin", "smartAPIs"],
    },
    {
      className: `sidebar-menu-link pl-6 subMenu `,
      name: t("Menu_ApiKeys"),
      link: "/apikeys",
      // icon: apikeys,
      dropdownId: "adminDropdown",
      roles: ["laigoAdmin", "smartAPIs"],
    },
    {
      className: `sidebar-menu-link pl-6 subMenu`,
      name: t("Menu_Mgmtdashboard"),
      link: "/Users",
      // icon: userRolesIcon,
      dropdownId: "adminDropdown",
      roles: ["laigoAdmin"],
    },
    {
      className: `sidebar-menu-link pl-6 subMenu `,
      name: t("Roles"),
      link: "/userRoles",
      // icon: userRolesIcon,
      dropdownId: "adminDropdown",
      roles: ["laigoAdmin"],
    },
    {
      className: "sidebar-menu-link text-white",
      name: t("Menu_subscription"),
      link: "/subscription",
      icon: subscriptionIcon,
      roles: ["laigoAdmin", "subscription"],
    },
  ];

  const isSelected = (path) => location.pathname === path;

  return (
    <div
      className={`sidebar z-30 fixed bottom-0 bg-[#12557D] border-r-2 border-solid border-[#333333ff] min-h-full hidden md:block lg:block duration-500 text-gray-100 h-full`}
      style={{
        width: open ? sidebarWidthOpen : sidebarWidthClosed,
      }}
    >
      <div className="pt-0 flex justify-end absolute left-[75px] h-0 z-20">
        <div
          className="transition-all duration-100 w-6 rounded-full border-[#222640ff] bg-[#222640ff] cursor-pointer align-middle mt-[-5px]"
          style={{
            height: "25px",
            marginLeft: open ? "190px" : "-100px",
            display: "flex",
            justifyContent: "center",
          }}
          onClick={handleSidebarClick}
        >
          <img
            alt=""
            className="cursor-pointer px-[8px] py-[4px]"
            src={sidebardMenuOpenIcon}
            style={{
              transform: open ? "rotate(180deg)" : "rotate(0deg)",
            }}
          />
        </div>
      </div>

      <div className="flex flex-col items-center" ref={dropdownRef}>
        {menus.map((menu, i) => {
          if (!isAuth || !hasAccessToMenu(menu.roles)) {
            return null;
          } else if (menu.isDropdownTrigger) {
            return (
              <Link
                key={i}
                className={`${
                  menu.className
                } flex items-center gap-3.5 font-medium ${
                  open ? "open" : ""
                } rounded-md cursor-pointer`}
                onClick={toggleAdminDropdown}
              >
                <img className="icon" src={menu.icon} alt="" />
                <h2
                  className={`duration-75 whitespace-nowrap text-white ${
                    !open && "opacity-0 translate-x-28 overflow-hidden"
                  }`}
                >
                  {menu.name}
                </h2>
              </Link>
            );
          } else if (menu.hidden) {
            return null;
          } else {
            return menu.dropdownId === "adminDropdown" ? (
              <div
                className={
                  adminDropdownOpen
                    ? "submenuContainer"
                    : "hidden submenuContainer"
                }
                key={i}
              >
                <Link
                  to={menu.link}
                  onClick={() => handleMenuItemClick(menu)}
                  className={`sidebar-menu-link submenuItem ${
                    isSelected(menu.link) ? "selected" : ""
                  } ${
                    open ? "open" : ""
                  } group flex items-center gap-3.5 font-medium rounded-md z-10`}
                >
                  {/* <img
                    src={menu.icon}
                    alt=""
                    className={` imgSubMenu icon ${
                      isSelected(menu.link) ? "selected" : ""
                    }`}
                    style={{
                      filter: isSelected(menu.link)
                        ? "none"
                        : "grayscale(100%) brightness(0) invert(1)",
                    }}
                  /> */}
                  <h2
                    className={`headerMargin duration-75 whitespace-nowrap text-white ${
                      !open && "opacity-0 translate-x-28 overflow-hidden"
                    }`}
                  >
                    {menu.name}
                  </h2>
                </Link>
              </div>
            ) : (
              <Link
                to={menu.link}
                key={i}
                onClick={() => handleMenuItemClick(menu)}
                className={`${isSelected(menu.link) ? "selected" : ""} ${
                  menu.className
                } ${
                  open ? "open" : ""
                } group flex items-center gap-3.5 font-medium rounded-md z-10`}
              >
                <img
                  src={menu.icon}
                  alt=""
                  className={`icon ${isSelected(menu.link) ? "selected" : ""}`}
                  style={{
                    filter: isSelected(menu.link)
                      ? "none"
                      : "grayscale(100%) brightness(0) invert(1)",
                  }}
                />
                <h2
                  className={`duration-75 whitespace-nowrap text-white ${
                    !open && "opacity-0 translate-x-28 overflow-hidden"
                  }`}
                >
                  {menu.name}
                </h2>
              </Link>
            );
          }
        })}
      </div>
    </div>
  );
};

export default withTranslation()(Sidebar);

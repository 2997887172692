import { createSlice } from "@reduxjs/toolkit";

const laigosSlice = createSlice({
  name: "laigos",
  initialState: {
    subscriptionName: "",
    availableLaigos: 0,
    usedLaigos: 0,
    expiredLaigos: 0,
    subscriptionValidFrom: "",
    subscriptionValidTo: "",
    modelsTimeUsage: [],
    dailyUsage: [],
    customSubscriptions: [
      {
        Id: "",
        Name: "",
        GrantedCount: 0,
        IterationCount: 0,
        IterationInterval: 0,
        IsExpiring: false,
        IsAutoDemo: false,
        IsSelectionDisabled: false,
        Prices: [],
      },
    ],
  },
  reducers: {
    setUserMetrics: (state, action) => {
      if (action.payload.length === 0) {
        return state;
      }

      const { customSubscriptions, ...otherMetrics } = action.payload[0];
      return {
        ...state,
        ...otherMetrics,
      };
    },
    updateModelsTimeUsage: (state, action) => {
      state.modelsTimeUsage = action.payload;
    },
    updateDailyUsage: (state, action) => {
      state.dailyUsage = action.payload;
    },
    setCustomSubscriptions: (state, action) => {
      state.customSubscriptions = action.payload;
    },
    reset: (state) => {
      state.subscriptionName = "";
      state.availableLaigos = 0;
      state.usedLaigos = 0;
      state.expiredLaigos = 0;
      state.subscriptionValidFrom = "";
      state.subscriptionValidTo = "";
      state.modelsTimeUsage = [];
      state.dailyUsage = [];
      state.customSubscriptions = [
        {
          Id: "",
          Name: "",
          GrantedCount: 0,
          IterationCount: 0,
          IterationInterval: 0,
          IsExpiring: false,
          IsAutoDemo: false,
          IsSelectionDisabled: false,
          Prices: [],
        },
      ];
    }
  },
});

export const {
  setUserMetrics,
  updateModelsTimeUsage,
  updateDailyUsage,
  setCustomSubscriptions,
  reset,
} = laigosSlice.actions;

export default laigosSlice.reducer;

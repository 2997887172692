import { createSlice } from "@reduxjs/toolkit";

export const notificationSlice = createSlice({
  name: "notification",
  initialState: {
    id: "",
    message: "",
    type: "info", // can be 'info', 'error', 'success', etc.
    bgColor: "bg-blue-500",
    show: false,
  },
  reducers: {
    showNotification: (state, action) => {
      state.id = action.payload.id;
      state.message = action.payload.message;
      state.type = action.payload.type;
      state.bgColor = action.payload.bgColor;
      state.show = true;
    },
    hideNotification: (state, action) => {
      state.show = false;
    },
  },
});

export const { showNotification, hideNotification } = notificationSlice.actions;

export default notificationSlice.reducer;

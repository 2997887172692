//import { useSelector, useDispatch } from "react-redux";
import { withTranslation } from "react-i18next";
import { useTranslation } from "react-i18next";
//import { getApiVersion } from "../../store/helpers/footer-action";
import packageVersion from "../../../package.json";

function Footer() {
  const { t } = useTranslation();
  //const dispatch = useDispatch();
  //dispatch(getApiVersion());
  //const apiVersion = useSelector((state) => state.footer.apiVersion);

  return (
    <footer
      className={"fixed text-[#8B8B8B] bg-[#404040] bottom-0 w-full z-40"}
    >
      <div className="grid grid-cols-2 py-3">
        <div className="mobile:pl-4 pl-12  my-auto lg:text-sm text-xs">
          smartTools Version {packageVersion.version}
        </div>
        <div className="mobile:pr-4 pr-12  my-auto ml-auto lg:text-sm text-xs">
          {" "}
          {t("Footer_Copyright")}{" "}
        </div>
      </div>
    </footer>
  );
}

export default withTranslation()(Footer);

import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

const clientCredentialsSlice = createSlice({
  name: "apiPage",
  initialState: {
    clientCredentialsExists: false,
    loading: false,
    errorMessage: "",
    clientCredentials: [],
    totalConsumptions: {
      dataset: [],
      labels: [],
      intervalDays: 30,
      total: 0,
      startDate: 0,
      endDate: 0,
      isInErrorState: false,
    },
    totalUserCounts: {
      dataset: [],
      labels: [],
      intervalDays: 30,
      total: 0,
      startDate: 0,
      endDate: 0,
      isInErrorState: false,
    },
  },
  reducers: {
    setClientCredentialsExists: (state, action) => {
      state.clientCredentialsExists = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setErrorMessage: (state, action) => {
      state.errorMessage = action.payload;
    },
    setCredentials: (state, action) => {
      state.clientCredentials = action.payload;
    },
    setGetTotalConsumptionsErrorState: (state, action) => {
      state.isTotalConsumptionsInErrorState = true;
    },
    setGetTotalUserCountsErrorState: (state, action) => {
      state.isTotalUserCountsInErrorState = true;
    },
    setTotalConsumptions: (state, action) => {
      let paddedConsumptionDataSet = [];
      let labels = [];

      const addMissingDates = (data, startDate, endDate) => {
        let consumptionMap = {};
        data.forEach((d) => {
          const dateKey = moment(d.date).format("YYYY-MM-DD");
          consumptionMap[dateKey] = d.consumption;
        });

        let currentDate = new Date(startDate);
        while (currentDate <= endDate) {
          const dateString = moment(currentDate).format("YYYY-MM-DD");

          if (!consumptionMap[dateString]) {
            data.push({
              date: `${dateString}T00:00:00Z`,
              consumption: 0,
            });
            consumptionMap[dateString] = 0;
          }

          currentDate.setDate(currentDate.getDate() + 1);
        }

        return data.sort((a, b) => new Date(a.date) - new Date(b.date));
      };

      var totalConsumption = 0;
      var paddedConsumptionData = addMissingDates(
        action.payload.data,
        action.payload.startDate,
        action.payload.endDate
      );

      Object.values(paddedConsumptionData).forEach((val, key) => {
        const currentDate = new Date(Date.parse(val.date));

        labels[key] = moment(currentDate).format("DD.MM.YYYY");
        paddedConsumptionDataSet[key] = val.consumption;

        totalConsumption = val.consumption + totalConsumption;
      });

      state.totalConsumptions = {
        dataset: paddedConsumptionDataSet,
        labels: labels,
        intervalDays: action.payload.interval,
        total: totalConsumption,
        startDate: action.payload.startDate,
        endDate: action.payload.endDate,
        isInErrorState: false,
        data: paddedConsumptionData.map((item, index) => {
          return { key: labels[index], data: item.consumption };
        }),
      };
    },

    setTotalUserCounts: (state, action) => {
      let paddedUserCountsDataSet = [];
      let labels = [];

      const addMissingDates = (payload) => {
        let data = payload.data;
        let currentDate = new Date(payload.endDate);

        while (currentDate >= payload.startDate) {
          const dateString = moment(currentDate).format("YYYY-MM-DD");
          const existingData = payload.data.find((d) =>
            d.date.startsWith(dateString)
          );
          if (!existingData) {
            data.push({
              date: `${dateString}T00:00:00Z`,
              count: 0,
            });
          }

          currentDate.setDate(currentDate.getDate() - 1);
        }

        return data.sort((a, b) => new Date(a.date) - new Date(b.date));
      };

      var totalUserCounts = 0;
      var paddedUserCountsData = addMissingDates(action.payload);

      Object.values(paddedUserCountsData).forEach((val, key) => {
        const currentDate = new Date(Date.parse(val.date));

        labels[key] = moment(currentDate).format("DD.MM.YYYY");
        paddedUserCountsDataSet[key] = val.count;

        totalUserCounts = val.count + totalUserCounts;
      });

      state.totalUserCounts = {
        dataset: paddedUserCountsDataSet,
        labels: labels,
        intervalDays: action.payload.interval,
        total: totalUserCounts,
        startDate: action.payload.startDate,
        endDate: action.payload.endDate,
        isInErrorState: false,
      };
    },
  },
});

export const {
  setClientCredentialsExists,
  setLoading,
  setErrorMessage,
  setCredentials,
  setGetTotalConsumptionsErrorState,
  setGetTotalUserCountsErrorState,
  setTotalConsumptions,
  setTotalUserCounts,
} = clientCredentialsSlice.actions;

export default clientCredentialsSlice.reducer;

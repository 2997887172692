import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  uploadState: 1,
  resultsForDownload: null,
  resultsReceived: false,
  uploadedFileName: "",
  invoiceData: null,
  ieJobId: null,
};

const smartInvoiceSlice = createSlice({
  name: "smartInvoice",
  initialState,
  reducers: {
    setUploadStateSmartInvoice: (state, action) => {
      state.uploadState = action.payload;
    },
    setResultsForDownload: (state, action) => {
      state.resultsForDownload = action.payload;
    },
    setResultsReceived: (state, action) => {
      state.resultsReceived = action.payload;
    },
    setUploadedFileName: (state, action) => {
      state.uploadedFileName = action.payload;
    },
    setSmartInvoiceData: (state, action) => {
      state.invoiceData = action.payload;
    },
    setJobIdSmartInvoice: (state, action) => {
      state.ieJobId = action.payload;
    },
  },
});

export const {
  setUploadStateSmartInvoice,
  setResultsForDownload,
  setResultsReceived,
  setUploadedFileName,
  setSmartInvoiceData,
  setJobIdSmartInvoice,
} = smartInvoiceSlice.actions;

export default smartInvoiceSlice.reducer;

import { createSlice } from "@reduxjs/toolkit";

const footerSlice = createSlice({
  name: "footer",
  initialState: {
    apiVersion: "1",
  },
  reducers: {
    setApiVersion: (state, action) => {
      state.apiVersion = action.payload;
    },
  },
});

export const { setApiVersion } = footerSlice.actions;

export default footerSlice.reducer;

import { createSlice } from "@reduxjs/toolkit";

const smartTableSlice = createSlice({
  name: "smartTable",
  initialState: {
    uploadState: 1,
    teJobId: 0,
    resultsForDownload: 0,
    uploadedFileName: "",
    resultsReceived: false,
    smartTableData: null,
  },
  reducers: {
    setUploadStateSmartTable: (state, action) => {
      state.uploadState = action.payload;
    },
    setJobIdSmartTable: (state, action) => {
      state.teJobId = action.payload;
    },
    setResultsForDownload: (state, action) => {
      state.resultsForDownload = action.payload;
    },
    setUploadedFileName: (state, action) => {
      state.uploadedFileName = action.payload;
    },
    setResultsReceived: (state, action) => {
      state.resultsReceived = action.payload;
    },
    setSmartTableData: (state, action) => {
      state.smartTableData = action.payload;
    },
  },
});

export const {
  setUploadStateSmartTable,
  setJobIdSmartTable,
  setResultsForDownload,
  setUploadedFileName,
  setResultsReceived,
  setSmartTableData,
} = smartTableSlice.actions;

export default smartTableSlice.reducer;

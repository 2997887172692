import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import packageVersion from "../package.json";
import Loading from "./pages/Loading";
import store from "./store/store";
import "./index.scss";
import App from "./App";
import "./i18n";
import KeycloakService from "./pages/KeycloakService";
import CacheBuster from "react-cache-buster";

const isProduction = process.env.NODE_ENV === "production";
// const isProduction = true;

let persistor = persistStore(store);
// const container = document.getElementById("root");

const container = document.getElementById("root");
const root = createRoot(container);

const renderApp = () =>
  root.render(
    <React.StrictMode>
      <CacheBuster
        currentVersion={packageVersion.version}
        isEnabled={isProduction} //If false, the library is disabled.
        isVerboseMode={false} //If true, the library writes verbose logs to console.
        loadingComponent={<Loading />} //If not pass, nothing appears at the time of new version check.
        metaFileDirectory={"."} //If public assets are hosted somewhere other than root on your server.
      >
        <Provider store={store}>
          <BrowserRouter>
            <PersistGate loading={null} persistor={persistor}>
              <App />
            </PersistGate>
          </BrowserRouter>
        </Provider>
      </CacheBuster>
    </React.StrictMode>
  );

KeycloakService.initKeycloak(renderApp);

import React from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import MobileMenu from "./MobileMenu";
import { t } from "i18next";
import { withTranslation } from "react-i18next";
import classes from "../../assets/css/components/base/Breadcrumbs.module.scss";
const Breadcrumbs = () => {
  const location = useLocation();
  const userProfile = useSelector((state) => state.Users.userProfile);

  const getBackgroundColor = (pathname) => {
    switch (pathname) {
      case "/":
        return "bg-[#274263ff]";
      case "/smartTable":
        return "bg-[#274263ff]";
      case "/smartPaperFlow":
        return "bg-[#274263ff]";
      case "/smartInvoice":
        return "bg-[#274263ff]";
      case "/smartICR":
        return "bg-[#274263ff]";
      case "/subscription":
        return "bg-[#274263ff]";
      case "/Users":
        return "bg-[#274263ff]";
      case "/userRoles":
        return "bg-[#274263ff]";
      case "/userprofile":
        return "bg-[#274263ff]";
      case "/apikeys":
        return "bg-[#274263ff]";
      case "/smartCustoms":
        return "bg-[#274263ff]";
      case "/secretRoute":
        return "bg-[#274263ff]";
      default:
        return "bg-[#0a0c10]";
    }
  };

  const getBreadcrumbText = (pathname) => {
    if (pathname === "/userprofile") {
      return userProfile === true ? t("User_Profile") : t("UserProfile_Title");
    }

    switch (pathname) {
      case "/":
      case "/smartTable":
        return t("Table_Extraction");
      case "/smartPaperFlow":
        return t("SmartPaperFlow");
      case "/smartInvoice":
        return t("Invoice_Extraction");
      case "/smartICR":
        return t("Text_Extraction");
      case "/subscription":
        return t("Menu_subscription");
      case "/Users":
        return t("Users_list");
      case "/userRoles":
        return t("Administration_Roles");
      case "/apikeys":
        return t("ApiPage_ApiKeys");
      case "/smartCustoms":
        return t("smartCustoms");
      case "/secretRoute":
        return "Welcome to super secret page. Shhh ";
      default:
        return "";
    }
  };

  const backgroundColor = getBackgroundColor(location.pathname);
  const breadcrumbText = getBreadcrumbText(location.pathname);

  return (
    <div
      className={
        backgroundColor +
        " " +
        classes.mainBreadcrumb +
        " fixed mt-12 z-20 lg:mt-12 lg:py-4 text-white w-full "
      }
    >
      <div className=" mobile:pl-4 pl-6 grid grid-cols-12  col">
        <div className="text-sm col-span-10 my-auto">{breadcrumbText}</div>
        <div className="col-span-2 w-16 ml-auto">
          <MobileMenu />
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(Breadcrumbs);

import React, { useState, useEffect } from "react";
import initialIcon from "../../assets/icons/initialPageIcon.svg";
import Button from "./Button";
import { checkWizardCompletion } from "../../store/helpers/api-actions";
import { completeWizard } from "../../store/helpers/api-actions";
import { useDispatch } from "react-redux";
import { t } from "i18next";

const InitialOverlay = () => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [isClosing, setIsClosing] = useState();

  useEffect(() => {
    const checkCompletion = async () => {
      const isWizardCompleted = await dispatch(checkWizardCompletion());
      if (isWizardCompleted) {
        setIsOpen(false);
      } else {
        setIsOpen(true);
      }
    };
    checkCompletion();
  }, [dispatch]);

  const handleCloseOverlay = async () => {
    setIsClosing(true);
    try {
      dispatch(completeWizard());
    } catch (error) {
      console.error("Error completing wizard:", error);
    }
  };

  return isOpen ? (
    <div
      className={` fixed inset-0 z-50 flex items-center justify-center bg-[#31a5be] bg-opacity-50 backdrop-blur transition-opacity ${
        isClosing ? "opacity-0 pointer-events-none" : "opacity-100"
      }`}
    >
      <div className=" grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 flex mobile:flex-col-reverse md:flex-col-reverse">
        <div className="mobile:p-8  lg:col-span-2 text-white lg:w-96 self-center mobile:text-center">
          <h1 className=" text-3xl  ">
            {t("Initial_Welcome_P1")} <br /> {t("Initial_Welcome_P2")}
          </h1>
          <p className="mt-4">{t("Initial_Text")}</p>
          <Button
            className="primaryBtn mt-8"
            text={t("Initial_Continue")}
            onClick={handleCloseOverlay}
          />
        </div>

        <div className="mx-auto lg:w-60">
          <img src={initialIcon} alt="Initial Icon" />
        </div>
      </div>
    </div>
  ) : null;
};

export default InitialOverlay;
